export default {
    'admiral': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-63",
        affiliateUserId: 19773,
    },
    'autovillage': {
        companyName: "AutoVillage",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-22",
        affiliateUserId: 1664,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        
    },
    'autovolo': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-41",
        affiliateUserId: 16588,
    },
    'allpurposeloans': {
        prePopulateForm: true,
        affiliateUserId: 21723,
    },
    'drivescore': {
        companyName: "DriveScore",
        prePopulateForm: false,
        affiliateUserId: 42294,
        theme: 'drivescore',
        classifiedPartner: true,
        themeColor: '#0B132E'
    },
    'mycarcheck': {
        prePopulateForm: false,
        affiliateUserId: 42399,
        theme: 'mycarcheck',
        themeColor: '#12325b'
    },
    'comparethemarket': {
        prePopulateForm: false,
        affiliateUserId: 42416,
        theme: 'comparethemarket',
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#003fd8'
    },
    'comparethemarketcarfinance': {
        companyName: "Compare the Market",
        prePopulateForm: false,
        affiliateUserId: 42416,
        modalShowLogo: true,
        showPowerBy: true,
        swapLogoSide: false,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: true,
        useModalV3DualBand: true,
        theme: 'comparethemarketcarfinance',
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#2843D0',
        tealiumEnabled: true,
        publicFolder: 'comparethemarket',
        underDevelopmentProd: false,
        underDevelopmentDev: false,
        domainNames: [
            'carfinance.comparethemarket.com'
        ],
        allowAutomaticTesting: true,
        interstitial:{
            show: false,
        },
        testB: false
    },
    'carcredible': {
        companyName: "Car Credible",
        prePopulateForm: false,
        affiliateUserId: 40588,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: true,
        useModelV3: true,
        useModelV3PowerBy: false,
    },
    'carsnitch': {
        companyName: "CarSnitch",
        prePopulateForm: false,
        affiliateUserId: 40643,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        
    },
    'carcraft': {
        companyName: "Carcraft",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-29",
        loadVehicleFromQuote: false,
        affiliateUserId: 2655,
        theme: 'carcraft',
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        themeColor: '#ff916f',
        showCF247Logo: false,
        showCF247Band: false,
        domainNames: [
            'apply.carcraft.co.uk'
        ],
    },
    'ckdirect': {
        prePopulateForm: false,
        affiliateUserId: 21746,
        modalShowLogo: true,
        showPowerBy: true,
        theme: 'ckdirect',
        enableContactDetailsTwoPages: false,
        interstitial:{
            show: false,
            trigger: {
                params: [
                    { key: 'utm_term', value: 'interactiveep' },
                ]
            }
        },
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#21292c',
        testB: false
    },
    'ckdirect-submission': {
        prePopulateForm: false,
        affiliateUserId: 41183,
        showPartnerModal: false,
        modalShowLogo: true,
        showVehicleType: true,
        showPowerBy: true,
        showCF247Logo: false,
        startPage: 'driving-licence',
        theme: 'ckdirect',
        prePopulateFormValidation: false,
        compactPageForContactDetail: false,
        themeColor: '#21292c'
    },
    'ckdirect-whitelabel': {
        prePopulateForm: false,
        affiliateUserId: 21746,
        showPartnerModal: false,
        modalShowLogo: true,
        showPowerBy: true,
        //startPage: 'driving-licence',
        theme: 'ckdirect',
        showCF247Logo: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#21292c'
    },
    'ckdirect-submission-whitelabel': {
        prePopulateForm: false,
        affiliateUserId: 41183,
        showPartnerModal: false,
        modalShowLogo: true,
        showVehicleType: true,
        showPowerBy: false,
        //startPage: 'driving-licence',
        theme: 'ckdirect',
        showCF247Logo: false,
        themeColor: '#21292c'
    },
    'carfinderscotland': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-42",
        affiliateUserId: 16568,
    },
    'cargurus': {
        companyName: "CarGurus",
        prePopulateForm: false,
        affiliateUserId: 21617,
        showPartnerModal: false,
        showPowerBy: false,
        swapLogoSide: true,
        enableContactDetailsTwoPages: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true
    },
    'cargurus-submission': {
        companyName: "CarGurus",
        prePopulateForm: false,
        affiliateUserId: 21617,
        showPartnerModal: true,
        modalShowLogo: true,
        showPowerBy: true,
        swapLogoSide: false,
        theme: 'cargurus-submission',
        themeColor: '#008002'
    },
    'carsite': {
        companyName: "CarSite",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-25",
        affiliateUserId: 2515,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        
    },
    'cazana': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-55",
        affiliateUserId: 18796,
    },
    'clearscore': {
        companyName: "ClearScore",
        prePopulateForm: true,
        googleAnalytics: "UA-219881-47",
        affiliateUserId: 17253,
        showCF247Logo: true,
        showPowerBy: false,
        theme: 'clearscore',
        showPowerByCF247Logo: false,
        themeColor: '#0f81a3'
    },
    'confused': {
        companyName: "Confused.com",
        prePopulateForm: true,
        googleAnalytics: "UA-219881-57",
        affiliateUserId: 19249,
        showPowerBy: true,
        showCF247Logo: true,
        theme: 'confused',
        showPowerByCF247Logo: false,
        domainNames: [
            'finance.confused.com'
        ],
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#ea9665'
    },
    'credability': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-71",
        affiliateUserId: 20123,
        showPowerBy: false,
        swapLogoSide: true
    },
    'credapp': {
        prePopulateForm: false,
        affiliateUserId: 21737,
        showPowerBy: true,
        swapLogoSide: false,
        howCF247Logo: true,
        showPowerByCF247Logo: false,
        theme: 'credapp',
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#7812F9'
    },
    'desperateseller': {
        companyName: "Desperate Seller",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-53",
        loadVehicleFromQuote: true,
        affiliateUserId: 18782,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        
    },
    'equifax': {
        prePopulateForm: false,
        affiliateUserId: 41999,
        showPartnerModal: false,
        modalShowLogo: true,
        showPowerBy: true,
        theme: 'equifax',
        themeColor: '#29343f'
    },
    'experian': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-38",
        affiliateUserId: 1798,
        theme: 'experian',
        themeColor: '#d82b80'
    },
    'facebook': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-56",
        affiliateUserId: null,
        showPartnerModal: true,
    },
    'freedomfinance': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-52",
        affiliateUserId: 3550,
    },
    'gowizard': {
        prePopulateForm: false,
        affiliateUserId: 20383,
        showPartnerModal: false,
        modalShowLogo: true,
    },
    'honestjohn': {
        companyName: "HonestJohn",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-44",
        affiliateUserId: 17094,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        
    },
    'knowyourmoney': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-54",
        affiliateUserId: 18781,
    },
    'money': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-61",
        affiliateUserId: 2428,
    },
    'moneyguru': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-67",
        affiliateUserId: 16758,
    },
    'moneysupermarket': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-16",
        affiliateUserId: 20351,
    },
    'moneysavingexpert': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-16",
        affiliateUserId: 20564,
    },
    'monevo': {
        prePopulateForm: true,
        googleAnalytics: "UA-219881-16",
        affiliateUserId: 17137,
    },
    'motors': {
        companyName: "Motors.co.uk Limited",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-40",
        loadVehicleFromQuote: true,
        affiliateUserId: 2775,
    },
    'oceancar': {
        prePopulateForm: false,
        affiliateUserId: 21884,
        showPowerBy: true,
        showCF247Logo: true,
        theme: 'oceancar',
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#282d5c',
        showBanner: false,
        bannerMessage: '&#127881;&#128663; Your chance to win £7,000 in our prize draw when you successfully take out car finance with Car Finance 247, don\'t miss out!!! &#127882; <a href=\'https://www.carfinance247.co.uk/blog-post/ocean-finance-prize-draw-full-terms-and-conditions\' target=\'_blank\'>T&Cs apply</a>',
    },
    'oceancar-submission': {
        prePopulateForm: false,
        affiliateUserId: 21884,
        showPartnerModal: false,
        showCF247Logo: false,
        theme: 'oceancar',
        themeProdEnabled: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#282d5c'
    },
    'oceanfinance': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-72",
        affiliateUserId: 20124,
    },
    'parkers': {
        companyName: "Parkers",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-48",
        loadVehicleFromQuote: true,
        affiliateUserId: 17447,
        modalShowLogo: true,
        showPowerBy: true,
        swapLogoSide: false,
        themeProdEnabled: true,
        theme: 'parkers',
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        themeColor: '#034ea2',
        
    },
    'parkers-submission': {
        companyName: "Parkers.co.uk Limited",
        prePopulateForm: false,
        googleAnalytics: "UA-219881-48",
        loadVehicleFromQuote: true,
        affiliateUserId: 17447,
        showPartnerModal: true,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        themeProdEnabled: true,
        theme: 'parkers',
        themeColor: '#034ea2'
    },
    'pistonheads': {
        companyName: "PistonHeads",
        prePopulateForm: false,
        affiliateUserId: 43118,
        showPartnerModal: false,
        showPowerBy: true,
        swapLogoSide: false,
        enableContactDetailsTwoPages: false,
        useModelV3: false,
        useModelV3PowerBy: false,
        classifiedPartner: true,
        theme: 'pistonheads',
        themeColor: '#1C2527'
    },
    'raccars': {
        companyName: "RAC Cars",
        prePopulateForm: false,
        //googleAnalytics: "UA-219881-45",
        affiliateUserId: 17177,
    },
    'usedcarsni': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-31",
        affiliateUserId: 1327,
    },
    'uswitch': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-21",
        affiliateUserId: 2411,
    },
    'time4leasing': {
        prePopulateForm: false,
        googleAnalytics: "UA-219881-16",
        affiliateUserId: null
    },
    'thinkmoney': {
        prePopulateForm: false,
        affiliateUserId: 2319,
        showPowerBy: false,
        swapLogoSide: true
    },
    'thinkmoneycar': {
        prePopulateForm: false,
        affiliateUserId: 21886,
        showPowerBy: true,
        swapLogoSide: false,
        showCF247Logo: true,
        showPowerByCF247Logo: false,
        theme: 'thinkmoneycar',
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: true,
        minLoanAmount: 3000,
        allowDefaultLoanAmount: false,
        themeColor: '#474747'
    },
    'youngcardriver': {
        companyName: "Young Car Driver",
        prePopulateForm: false,
        affiliateUserId: 2400,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
    },
    'dragonfinance': {
        companyName: "Dragon Finance",
        prePopulateForm: false,
        affiliateUserId: 42157,
        showPowerBy: false,
        swapLogoSide: true,
        modalShowLogo: true,
        showPartnerModal: true,
        useModelV3: true,
        useModelV3PowerBy: false,
    },
    'firstcentralinsurance': {
        companyName: "1st Central Insurance",
        prePopulateForm: false,
        affiliateUserId: 21341,
        showPowerBy: false,
        swapLogoSide: true,
        modalShowLogo: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
    },
    '1stcentral': {
        companyName: "1st Central Insurance",
        prePopulateForm: false,
        affiliateUserId: 21341,
        showPowerBy: false,
        swapLogoSide: true,
        modalShowLogo: true,
        showPartnerModal: false,
        useModelV3: false,
        useModelV3PowerBy: false,
    },
    'cheapcarsforsale': {
        prePopulateForm: false,
        affiliateUserId: 42171,
        showPowerBy: false,
        swapLogoSide: false,
        showPartnerModal: false,
        modalShowLogo: true,
        theme: 'cheapcarsforsale',
        themeColor: '#0064FF'
    },
    'loaneo': {
        companyName: "Loaneo",
        prePopulateForm: false,
        affiliateUserId: 42170,
        modalShowLogo: true,
        showPowerBy: false,
        swapLogoSide: false,
        showPartnerModal: false,
        useModelV3: true,
        useModelV3PowerBy: false,
    },
    'prefinance': {
        companyName: "Pre Finance",
        prePopulateForm: false,
        //googleAnalytics: "UA-219881-45",
        affiliateUserId: 43424,
    },
}
